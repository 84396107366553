<template>
  <div
    class="d-flex flex-row align-content-center align-items-center"
  >
    <span
      class="pointer fa fa-plus text-blue-light"
      @click="$emit('on:add-link-click')"
    />
    <span
      class="ml-2 pointer light-blue-col-title"
      @click="$emit('on:add-link-click')"
    >
      {{ $t(textLink) }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    textLink: {
      type: String,
      default: ''
    }
  }
}
</script>
